import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen as fasDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { faGift as fasGift } from "@fortawesome/free-solid-svg-icons";
import "./TabContainer.css";

const TabContainer = () => {
    const [error, setError] = useState(null);

    return (
        <div className="tab-container">
            {error ? (
                <div className="error-message">Something went wrong: {error.message}</div>
            ) : (
                <>
                    <div className="tab-bar">
                        <NavLink
                            to="/explore"
                            className={({ isActive }) => (isActive ? "tab active" : "tab")}
                        >
                            {/* <FontAwesomeIcon icon={fasDoorOpen} /> */}
                            <div>
                                Explore
                                <span className="superscript">AI</span>
                            </div>
                        </NavLink>
                        <NavLink
                            to="/saved"
                            className={({ isActive }) => (isActive ? "tab active" : "tab")}
                        >
                            {/* <FontAwesomeIcon icon={fasHeart} /> */}
                            <div>Saved</div>
                        </NavLink>
                        <NavLink
                            to="/offers"
                            className={({ isActive }) => (isActive ? "tab active" : "tab")}
                        >
                            {/* <FontAwesomeIcon icon={fasGift} /> */}
                            <div>Offers</div>
                        </NavLink>
                    </div>
                    <div className="tab-content">
                        <ErrorBoundary setError={setError}>
                            <Outlet />
                        </ErrorBoundary>
                    </div>
                </>
            )}
        </div>
    );
};

const ErrorBoundary = ({ children, setError }) => {
    try {
        return children;
    } catch (error) {
        console.error("Error rendering component:", error);
        setError(error);
        return <div className="error-message">Failed to load component</div>;
    }
};

export default TabContainer;
