import React, { createContext, useState, useEffect } from 'react';

export const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const storedLat = localStorage.getItem('lat');
    const storedLng = localStorage.getItem('lng');

    if (storedLat && storedLng) {
      setLocation({ lat: parseFloat(storedLat), lng: parseFloat(storedLng) });
      setLoading(false);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          localStorage.setItem('lat', latitude);
          localStorage.setItem('lng', longitude);
          setLoading(false);
          setError(null); // Clear any previous errors
        },
        (error) => {
          console.error(error);
          setError("Failed to retrieve location. Please try again.");
          setLoading(false); // Set loading to false even on error to prevent infinite loading state
        }
      );
    }
  }, []);

  return (
    <LocationContext.Provider value={{ location, setLocation, loading, error }}>
      {children}
    </LocationContext.Provider>
  );
};
