import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import "./Feedback.css";
import { useAuth } from "../AuthContext"; // Use your custom AuthContext hook
import axios from "axios";

const Feedback = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [submissionError, setSubmissionError] = useState(null); 
  const { isSignedIn } = useAuth(); // Renamed to isSignedIn
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const sanitizedFeedback = DOMPurify.sanitize(data.feedbackText);
    const sanitizedEmail = data.feedbackEmail ? DOMPurify.sanitize(data.feedbackEmail) : "";

    console.log("Sanitized Feedback:", sanitizedFeedback);
    console.log("Sanitized Email:", sanitizedEmail);

    setLoading(true); 
    setSubmissionError(null); 
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_PORT_URL}/api/feedback`, {
        feedbackText: sanitizedFeedback,
        feedbackEmail: sanitizedEmail || null,
      });
    
      console.log("Backend response:", response);
    
      if (response.status === 200) {
        console.log("Feedback sent successfully:", response.data);
        setSubmitted(true);
        reset(); 
      } else {
        console.error("Failed to send feedback:", response.data);
        setSubmissionError("Failed to send feedback. Please try again.");
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
      setSubmissionError("Error sending feedback. Please try again.");
    } finally {
      setLoading(false);
    }
    

  return (
    <div className="feedback-container">
      {submitted ? (
        <p className="submitted">Thank you for your feedback!</p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="feedback-container-inner">
            <h2>Provide Your Feedback</h2>
            <div className="form" action="#" method="post">
              <textarea
                id="feedback-text"
                placeholder="What's on your mind?"
                name="feedbackText"
                {...register("feedbackText", {
                  required: "Feedback is required",
                  maxLength: {
                    value: 500,
                    message: "Feedback must be less than 500 characters",
                  },
                })}
              ></textarea>
              {errors.feedbackText && (
                <p className="error-message">{errors.feedbackText.message}</p>
              )}
              {!isSignedIn && ( 
                <div className="email-container">
                  <label htmlFor="feedback-email">Email (Optional):</label>
                  <input
                    type="email"
                    id="feedback-email"
                    placeholder="Got feedback? We'll be in touch."
                    name="feedbackEmail"
                    {...register("feedbackEmail", {
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.feedbackEmail && (
                    <p className="error-message">{errors.feedbackEmail.message}</p>
                  )}
                </div>
              )}
              <div className="button-container">
                <button type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
              {submissionError && (
                <p className="error-message">{submissionError}</p>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

}

export default Feedback;
