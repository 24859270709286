import React, { useState } from "react";
import "./Menu.css";

const menus = [
  {
    id: 1,
    type: "image",
    images: [
      "https://via.placeholder.com/400x500",
      "https://via.placeholder.com/400x500/0000FF",
      "https://via.placeholder.com/400x500/FFFF00",
      "https://via.placeholder.com/400x500/FF00FF",
      "https://via.placeholder.com/400x500/00FFFF",
    ],
    alt: "Menu 1",
  },
];

const Menu = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : menus[0].images.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < menus[0].images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const ImageWithLoading = ({ src, alt }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    return (
      <div style={{ position: "relative" }}>
        {isLoading && <div className="image-placeholder">Loading...</div>}
        {hasError && <div className="image-error">Image not available</div>}
        <img
          src={src}
          alt={alt}
          style={{ display: isLoading || hasError ? "none" : "block", width: "100%" }}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setHasError(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className="carousel-menu">
      {menus[0].images.length > 0 ? (
        <>
          <button className="arrow left" onClick={handlePrevClick}>&lt;</button>
          <img src={menus[0].images[activeIndex]} alt={`Image ${activeIndex + 1}`} />
          <button className="arrow right" onClick={handleNextClick}>&gt;</button>
          <div className="dot-container">
            {menus[0].images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === activeIndex ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="placeholder-menu">
          Menu coming soon!
        </div>
      )}
    </div>
  );
};

export default Menu;
