import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as farHeart,
  faHeart as fasHeart,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { useSavedRestaurants } from "../SavedRestaurantContext";
import Info from "./Info";
import Menu from "./Menu";
import Offers from "./Offers";
import Modal from "../Modal/Modal";
import "./RestaurantCardDetails.css";
import { FaApple } from "react-icons/fa";
import GoogleIcon from "../../Assets/icons8-google.svg";
import { LocationContext } from "../../LocationContext.jsx";
import { useAuth } from "../AuthContext"; // Use the custom AuthContext

const RestaurantCardDetails = () => {
  const location = useLocation();
  const { restaurantId } = useParams();
  const [selectedTab, setSelectedTab] = useState(
    location.state?.defaultTab || "info"
  );
  const { isSignedIn, signIn, signOut, user } = useAuth(); // Use functions from custom AuthContext
  const { savedRestaurantIds, saveRestaurant, unsaveRestaurant } =
    useSavedRestaurants();
  const [restaurant, setRestaurant] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { location: userLocation } = useContext(LocationContext);

  // Function to add restaurant data to local storage
  function addRestaurantToLocalStorage(
    restaurantId,
    interactionType = "click"
  ) {
    let storedData = JSON.parse(localStorage.getItem("restaurantData")) || [];

    // Check if the restaurant is already stored
    const existingEntryIndex = storedData.findIndex(
      (item) => item.restaurantId === restaurantId
    );

    if (existingEntryIndex === -1) {
      // If not found, add new entry
      const newEntry = {
        restaurantId: restaurantId,
        mainCuisine: [], // Placeholder for future use
        interaction_type: interactionType, // Use the passed interaction type
      };
      storedData.push(newEntry);
    } else {
      // If found, update the interaction_type if needed
      storedData[existingEntryIndex].interaction_type = interactionType;
    }

    localStorage.setItem("restaurantData", JSON.stringify(storedData));
  }

  useEffect(() => {
    console.log("Fetching restaurant details for restaurantId:", restaurantId);

    const fetchRestaurantDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_PORT_URL}/api/restaurant/${restaurantId}`,
          {
            params: { lat: userLocation.lat, long: userLocation.lng },
            withCredentials: true, // Include cookies with the request
          }
        );

        const { restaurant, photos, tags, workingHours, distance } =
          response.data;

        setRestaurant({ ...restaurant, photos, tags, workingHours, distance });
        setImages(photos || []);
        setIsSaved(savedRestaurantIds.includes(parseInt(restaurantId)));

        // Add the restaurant ID to local storage with "click" interaction type
        addRestaurantToLocalStorage(parseInt(restaurantId), "click");

        setIsLoading(false);
      } catch (err) {
        setError("Failed to fetch restaurant details");
        console.error("Error fetching restaurant details:", err);
        setIsLoading(false);
      }
    };

    fetchRestaurantDetails();
  }, [restaurantId, savedRestaurantIds, userLocation]);

  const toggleSave = async () => {
    if (!isSignedIn) {
      // Check if user is signed in
      setIsModalOpen(true);
      return;
    }
    try {
      if (isSaved) {
        await unsaveRestaurant(parseInt(restaurantId));
      } else {
        await saveRestaurant({ ...restaurant, id: parseInt(restaurantId) });
      }
      setIsSaved(!isSaved);
    } catch (error) {
      console.error("Error toggling save:", error);
      setError("Failed to save/unsave restaurant. Please try again.");
    }
  };

  const goToPrevious = () =>
    setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  const goToNext = () =>
    setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));

  const handleShare = async () => {
    const url = window.location.href;
    const text = "Check out this restaurant!";
    try {
      if (navigator.share) {
        await navigator.share({ title: "Restaurant Share", text, url });
        alert("Content shared successfully");
      } else {
        await navigator.clipboard.writeText(url);
        alert("URL copied to clipboard");
      }
    } catch (error) {
      alert("Failed to share content");
      console.error("Error sharing content", error);
    }
  };

  if (isLoading) return <div className="centered-message">Loading...</div>;
  if (error) return <div className="centered-message">Error: {error}</div>;
  if (!restaurant) return <div className="centered-message">No restaurant found.</div>;

  const defaultImage =
    "https://10619-2.s.cdn12.com/rests/original/503_504917217.jpg";
  const imageUrl = images.length > 0 ? images[currentImageIndex] : defaultImage;

  const {
    name,
    latitude,
    longitude,
    rating,
    total_reviews,
    affordability,
    tags,
    workingHours,
    contact,
    full_address,
    distance,
  } = restaurant;

  return (
    <div className="restaurant-details-container">
      <div className="carousel">
        <button className="carousel-btn carousel-prev" onClick={goToPrevious}>
          {"<"}
        </button>
        <img
          src={imageUrl}
          alt="Restaurant"
          loading="lazy"
          onError={(e) => (e.target.src = defaultImage)}
        />
        <button className="carousel-btn carousel-next" onClick={goToNext}>
          {">"}
        </button>

        <div className="carousel-icons">
          <FontAwesomeIcon
            icon={isSaved ? fasHeart : farHeart}
            onClick={toggleSave}
            style={isSaved ? { color: "#fb6f92" } : {}}
          />
          <FontAwesomeIcon icon={faShareNodes} onClick={handleShare} />
        </div>
      </div>

      <div className="tabs">
        <button
          onClick={() => setSelectedTab("info")}
          className={selectedTab === "info" ? "active" : ""}
        >
          Info
        </button>
        <button
          onClick={() => setSelectedTab("menu")}
          className={selectedTab === "menu" ? "active" : ""}
        >
          Menu
        </button>
        <button
          onClick={() => setSelectedTab("offers")}
          className={selectedTab === "offers" ? "active" : ""}
        >
          Offers
        </button>
      </div>
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <div className="centered-message">
            <p>Sign in and find your favorite restaurants anytime!</p>
          </div>
          <button
            className="google-signin-button-saved"
            onClick={() => {
              try {
                signIn(); // Use signIn for Google
              } catch (error) {
                console.error("Error signing in with Google:", error);
                setError("Failed to sign in with Google. Please try again.");
              }
            }}
          >
            <img src={GoogleIcon} alt="Google" className="google-signin-icon" />
            Sign In with Google
          </button>
          <button
            className="apple-signin-button-saved"
            onClick={() => {
              try {
                signIn(); // Assume Apple sign-in uses the same method
              } catch (error) {
                console.error("Error signing in with Apple:", error);
                setError("Failed to sign in with Apple. Please try again.");
              }
            }}
          >
            <FaApple className="apple-signin-icon" /> Sign In with Apple
          </button>
          <div className="terms">
            By signing in, you agree to our Terms of Use and Privacy Policy
          </div>
        </Modal>
      )}
      <div className="tab-content">
        {selectedTab === "info" && (
          <Info
            restaurantName={name}
            latitude={latitude}
            longitude={longitude}
            rating={rating}
            totalReviews={total_reviews}
            affordability={affordability}
            tags={tags}
            workingHours={workingHours}
            contact={contact}
            address={full_address}
            distance={distance}
          />
        )}
        {selectedTab === "menu" && <Menu />}
        {selectedTab === "offers" && <Offers offers={restaurant.offers} />}
      </div>
    </div>
  );
};

export default RestaurantCardDetails;
