import React, { useState, useEffect, useRef, useContext } from "react";
import RestaurantCard from "../RestaurantCard/RestaurantCard";
import "./ExploreTab.css";
import { useAuth } from "../AuthContext"; // Import the custom AuthContext hook
import { useSavedRestaurants } from "../SavedRestaurantContext.jsx";
import PopupModal from "../PopupModal/PopupModal.jsx";
import Masonry from "react-masonry-css";
import axios from "axios";
import { LocationContext } from "../../LocationContext.jsx";

const ExploreTab = () => {
  const { location, loading } = useContext(LocationContext);
  const [restaurants, setRestaurants] = useState([]);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // State to handle errors
  const loadingRef = useRef(null);
  const { savedRestaurantIds, unsaveRestaurant, saveRestaurant } =
    useSavedRestaurants();
  const { isSignedIn, interactionCount } = useAuth(); // Use isSignedIn from the custom AuthContext
  const [showPopup, setShowPopup] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  useEffect(() => {
    const firstVisit = localStorage.getItem("firstVisit");

    if (isSignedIn) {
        if (interactionCount !== null) {
            if (!interactionCount && firstVisit === "false") {
                setShowPopup(true);
            }
        }
    } else if (!firstVisit) {
        // This handles non-signed-in users and first visit
        setShowPopup(true);
        localStorage.setItem("firstVisit", "true");
    }
}, [interactionCount, isSignedIn]);

  const handlePopupClose = (profileCompleted) => {
    
    setShowPopup(false);

    if (profileCompleted) {
      
      localStorage.setItem("profileComplete", "true");
    } else {
      
      localStorage.setItem("profileComplete", "false");
    }
  };

  const loadRestaurants = async (reset = false) => {
    if (isAllLoaded || isLoading || loading) return; // Wait until location is loaded
    setIsLoading(true);
    setError(null); // Reset error state before loading

    try {
        // Retrieve user interactions from local storage
        const userInteractions =
            JSON.parse(localStorage.getItem("restaurantData")) || [];

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT_URL}/api/restaurantrecommendation`,
            {
                lat: location.lat,
                lng: location.lng,
                userInteractions, // Include user interactions in the API call
                searchquery: "",
                limit,
                offset: reset ? 0 : offset,
            },
            {
                withCredentials: true, // Include cookies with the request
            }
        );

        if (!response.data || !Array.isArray(response.data.recommendations)) {
            throw new Error("Invalid response structure");
        }

        const newRestaurants = response.data.recommendations.map((restaurant) => {
            return {
                ...restaurant,
                isSaved: savedRestaurantIds.includes(restaurant.restaurant_id),
            };
        });

        setRestaurants((prev) =>
            reset ? newRestaurants : [...prev, ...newRestaurants]
        );

        if (newRestaurants.length < limit) {
            setIsAllLoaded(true);
        }
        setOffset((prev) => (reset ? limit : prev + limit));
    } catch (error) {
        console.error("Failed to load restaurants:", error);
        setError("Failed to load restaurants. Please try again later.");
    } finally {
        setIsLoading(false);
    }
};


  // Initial load
  useEffect(() => {
    if (!loading) {
      loadRestaurants(true);
    }
  }, [loading]); // Initial load effect

  // Load restaurants whenever location changes
  useEffect(() => {
    if (!loading) {
      // Clear restaurant list and reset pagination whenever location changes
      setRestaurants([]);
      setIsAllLoaded(false);
      setOffset(0);
      loadRestaurants(true);
    }
  }, [location, loading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isAllLoaded && !isLoading) {
          loadRestaurants();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [isLoading, isAllLoaded]);

  const toggleSave = (restaurant) => {
    try {
      if (!isSignedIn) {
        // Check if the user is signed in
        alert("Please sign in to save restaurants.");
        return;
      }
      const isCurrentlySaved = savedRestaurantIds.includes(
        restaurant.restaurant_id
      );
      isCurrentlySaved
        ? unsaveRestaurant(restaurant.restaurant_id)
        : saveRestaurant(restaurant);
    } catch (error) {
      console.error("Failed to toggle save state:", error);
      setError("Failed to update save state. Please try again later.");
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    480: 2,
  };

  return (
    <div className="explore-tab-content">
      {showPopup && <PopupModal onClose={handlePopupClose} />}
      {isLoading && restaurants.length === 0 ? (
        <div className="centered-message">Loading restaurants...</div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : restaurants.length > 0 ? (
        <>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid-explore"
            columnClassName="my-masonry-grid_column-explore"
          >
            {restaurants.map((restaurant) => (
              
              <RestaurantCard
                key={restaurant.restaurant_id}
                restaurant={restaurant}
                onSave={() => toggleSave(restaurant)}
                isSaved={savedRestaurantIds.includes(restaurant.restaurant_id)}
                isSignedIn={isSignedIn} // Pass the isSignedIn prop to RestaurantCard
              />
            ))}
          </Masonry>
          <div
            ref={loadingRef}
            style={{ height: "20px", margin: "10px 0" }}
          ></div>
          {isAllLoaded && <div>No more restaurants to load</div>}
        </>
      ) : (
        <div>No restaurants found.</div>
      )}
    </div>
  );
};

export default ExploreTab;
