import React, { useState, useEffect, useRef, useContext } from "react";
import AddressInput from "./AddressInput";
import AddressDropdown from "./AddressDropdown";
import useLocation from "./useLocation";
import useAddress from "./useAddress";
import MarkhamBoundary from "../../Assets/Markham.json";
import { LocationContext } from "../../LocationContext";
import "./AddressBar.css";

const BOUNDARY_POLYGON = MarkhamBoundary;

const AddressBar = () => {
  const [address, setAddress] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [recentAddresses, setRecentAddresses] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const dropdownRef = useRef(null);
  const inputFieldRef = useRef(null);

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const { setLocation } = useContext(LocationContext);
  const { loadingLocation, fetchCurrentLocation } = useLocation(
    setAddress,
    setTempAddress,
    GOOGLE_API_KEY
  );

  const {
    addressError,
    suggestions,
    handleChange,
    selectAddress,
    handleSubmit,
    handleBlur,
    setAddressError,
  } = useAddress(
    GOOGLE_API_KEY,
    BOUNDARY_POLYGON,
    setAddress,
    tempAddress,
    setTempAddress,
    setRecentAddresses,
    recentAddresses,
    setDropdownVisible,
    setErrorVisible,
    setLocation
  );

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputFieldRef.current !== event.target
    ) {
      setDropdownVisible(false);
      setErrorVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    if (errorVisible) {
      setDropdownVisible(false);
    } else {
      setDropdownVisible(true);
    }
    setTempAddress("");
    setIsFullScreen(window.innerWidth <= 480);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  useEffect(() => {
    if (addressError) {
      setErrorVisible(true);
      setDropdownVisible(false);
    } else {
      setErrorVisible(false);
    }
  }, [addressError]);

  useEffect(() => {
    if (
      inputFieldRef.current === document.activeElement ||
      suggestions.length > 0
    ) {
      setDropdownVisible(true);
    } else if (
      dropdownRef.current &&
      !dropdownRef.current.contains(document.activeElement)
    ) {
      setDropdownVisible(false);
    }
  }, [suggestions, inputFieldRef]);

  return (
    <div
      className={`address-input-container ${isFullScreen ? "full-screen" : ""}`}
      onClick={handleClickOutside}
    >
      <form
        onSubmit={(e) => {
          try {
            handleSubmit(e);
          } catch (error) {
            console.error("Error handling submit:", error);
          }
          closeFullScreen();
        }}
      >
        <AddressInput
          tempAddress={tempAddress}
          handleChange={(value) => {
            try {
              handleChange(value);
            } catch (error) {
              console.error("Error handling change:", error);
            }
          }}
          handleKeyDown={(e) => {
            if (e.key === "Enter") {
              try {
                handleSubmit(e);
              } catch (error) {
                console.error("Error handling key down:", error);
              }
              closeFullScreen();
            }
          }}
          handleBlur={() => {
            try {
              handleBlur();
            } catch (error) {
              console.error("Error handling blur:", error);
            }
          }}
          handleFocus={handleFocus}
          addressError={addressError}
          inputFieldRef={inputFieldRef}
          dropdownVisible={dropdownVisible}
          errorVisible={errorVisible}
          setAddressError={setAddressError}
        />
      </form>

      <ul
        className={`address-dropdown-b ${
          errorVisible ? "active no-radius" : "hidden"
        }`}
      >
        <li>{addressError}</li>
      </ul>

      <AddressDropdown
        dropdownVisible={dropdownVisible}
        recentAddresses={recentAddresses}
        selectAddress={(address) => {
          try {
            selectAddress(address);
          } catch (error) {
            console.error("Error selecting address:", error);
          }
          closeFullScreen();
        }}
        fetchCurrentLocation={() => {
          try {
            fetchCurrentLocation(closeFullScreen);
          } catch (error) {
            console.error("Error fetching current location:", error);
          }
        }}
        dropdownRef={dropdownRef}
        suggestions={suggestions}
        selectSuggestion={(address) => {
          try {
            selectAddress(address);
          } catch (error) {
            console.error("Error selecting suggestion:", error);
          }
          closeFullScreen();
        }}
      />

      {isFullScreen && (
        <button onClick={closeFullScreen} className="close-fullscreen-btn">
          X
        </button>
      )}
    </div>
  );
};

export default AddressBar;
