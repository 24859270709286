import { useState, useRef, useCallback, useEffect } from "react";
import debounce from "lodash/debounce";
import DOMPurify from "dompurify";
import {
  fetchAutocompleteSuggestions,
  validateAddressWithinBoundary,
} from "./LocationFetcher";

const useAddress = (
  GOOGLE_API_KEY,
  BOUNDARY_POLYGON,
  setAddress,
  tempAddress,
  setTempAddress,
  setRecentAddresses,
  recentAddresses,
  setDropdownVisible,
  setErrorVisible,
  setLocation
) => {
  const [addressError, setAddressError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const isSelectingRecentAddress = useRef(false);
  const autocompleteCache = useRef({});
  const lastValidAddressRef = useRef("");
  const lastFetchedAddressRef = useRef("");
  const debouncedFetchAutocompleteSuggestionsRef = useRef(null);
  const isDeletionRef = useRef(false);

  useEffect(() => {
    // Suggestions updated effect
  }, [suggestions]);

  const getEditDistance = (a, b) => {
    if (!a.length) return b.length;
    if (!b.length) return a.length;

    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b[i - 1] === a[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // Substitution
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1) // Insertion or deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  };

  const handleChange = (newAddress) => {
    isDeletionRef.current = newAddress.length < lastValidAddressRef.current.length;
  
    const sanitizedAddress = DOMPurify.sanitize(newAddress);
  
    if (addressError) {
      setAddressError("");
      setErrorVisible(false);
      setDropdownVisible(true);
    }
  
    setTempAddress(sanitizedAddress);
    lastValidAddressRef.current = sanitizedAddress;
  
    // Trigger the debounced function
    debouncedFetchAutocompleteSuggestionsRef.current(sanitizedAddress);
  };
  

  const handleBlur = () => {
    setTempAddress(lastValidAddressRef.current);
    setAddressError("");
    setDropdownVisible(false);
    setErrorVisible(false);
  };

  const isSignificantChange = (oldAddress, newAddress) => {
    if (oldAddress === newAddress) {
      return false;
    }

    let diffCount = 0;
    const length = Math.max(oldAddress.length, newAddress.length);
    for (let i = 0; i < length; i++) {
      if (oldAddress[i] !== newAddress[i]) {
        diffCount++;
      }
      if (diffCount > 2) {
        return true;
      }
    }
    return false;
  };

  const debouncedFetchAutocompleteSuggestions = useCallback(
    debounce(async (inputAddress) => {
      const sanitizedAddress = DOMPurify.sanitize(inputAddress);
  
      if (autocompleteCache.current[sanitizedAddress]) {
        setSuggestions(autocompleteCache.current[sanitizedAddress]);
      } else {
        const autocompleteSuggestions = await fetchAutocompleteSuggestions(
          sanitizedAddress,
          GOOGLE_API_KEY
        );
        autocompleteCache.current[sanitizedAddress] = autocompleteSuggestions;
        setSuggestions(autocompleteSuggestions);
      }
    }, 500),
    [GOOGLE_API_KEY]
  );
  

  const debouncedFetchAutocompleteSuggestionsForDeletion = useCallback(
    debounce(async (inputAddress) => {
      const sanitizedAddress = DOMPurify.sanitize(inputAddress);
      if (autocompleteCache.current[sanitizedAddress]) {
        setSuggestions(autocompleteCache.current[sanitizedAddress]);
      } else {
        const autocompleteSuggestions = await fetchAutocompleteSuggestions(
          sanitizedAddress,
          GOOGLE_API_KEY
        );
        autocompleteCache.current[sanitizedAddress] = autocompleteSuggestions;
        setSuggestions(autocompleteSuggestions);
      }
    }, 3000),
    [GOOGLE_API_KEY]
  );

  debouncedFetchAutocompleteSuggestionsRef.current = debouncedFetchAutocompleteSuggestions;
  const debouncedFetchAutocompleteSuggestionsForDeletionRef = useRef(debouncedFetchAutocompleteSuggestionsForDeletion);

  const formatAddress = (address) => {
    if (!address) return "";
    const addressComponents = address.split(",");
    const streetPart = addressComponents[0].trim();
    const secondPart = addressComponents[1] && addressComponents[1].trim();
    const hasStreetSuffix =
      secondPart &&
      (secondPart.match(
        /\b(st|street|ave|avenue|blvd|boulevard|rd|road|dr|drive|ln|lane|pl|place|court|ct|way|terr|terrace|cres|crescent|parkway|pkwy|circle|cir)\b/i
      ) ||
        /\d/.test(secondPart));

    return hasStreetSuffix ? `${streetPart}, ${secondPart}` : streetPart;
  };

  const selectAddress = async (address) => {
    isSelectingRecentAddress.current = true;
    setAddressError("");
    setErrorVisible(false);

    const validAddress = await validateAddressWithinBoundary(
      address.description || address
    );

    if (validAddress) {
      const formattedAddress = formatAddress(validAddress.address);
      setAddress(formattedAddress);
      setTempAddress(formattedAddress);
      setLocation({ lat: parseFloat(validAddress.lat), lng: parseFloat(validAddress.lng) });
      localStorage.setItem("userAddress", formattedAddress);
      localStorage.setItem("lat", validAddress.lat);
      localStorage.setItem("lng", validAddress.lng);
      if (!recentAddresses.includes(formattedAddress)) {
        setRecentAddresses([formattedAddress, ...recentAddresses].slice(0, 3));
      }
      lastValidAddressRef.current = formattedAddress;
    } else {
      const defaultAddress = "101 Town Center Blvd";
      const formattedDefaultAddress = formatAddress(defaultAddress);
      setAddress(formattedDefaultAddress);
      setTempAddress(formattedDefaultAddress);
      setLocation({ lat: 43.856098, lng: -79.337021 });
      localStorage.setItem("userAddress", formattedDefaultAddress);
      localStorage.setItem("lat", 43.856098);
      localStorage.setItem("lng", -79.337021);

      setAddressError("Oops! That's outside Markham. Using City Hall instead.");
      setErrorVisible(true);
      lastValidAddressRef.current = formattedDefaultAddress;
    }
    isSelectingRecentAddress.current = false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (debouncedFetchAutocompleteSuggestionsRef.current) {
      debouncedFetchAutocompleteSuggestionsRef.current.cancel();
    }

    const sanitizedAddress = DOMPurify.sanitize(tempAddress);

    const validAddress = await validateAddressWithinBoundary(
      sanitizedAddress,
      BOUNDARY_POLYGON,
      GOOGLE_API_KEY
    );

    if (validAddress) {
      const formattedAddress = formatAddress(validAddress.address);
      setAddress(formattedAddress);
      setTempAddress(formattedAddress);
      setAddressError("");
      setErrorVisible(false);
      setDropdownVisible(false);
      setLocation({ lat: parseFloat(validAddress.lat), lng: parseFloat(validAddress.lng) });
      localStorage.setItem("userAddress", formattedAddress);
      localStorage.setItem("lat", validAddress.lat);
      localStorage.setItem("lng", validAddress.lng);
      if (!recentAddresses.includes(formattedAddress)) {
        setRecentAddresses([formattedAddress, ...recentAddresses].slice(0, 4));
      }
      lastValidAddressRef.current = formattedAddress;
    } else {
      const defaultAddress = "101 Town Center Blvd";
      const formattedDefaultAddress = formatAddress(defaultAddress);
      setAddress(formattedDefaultAddress);
      setTempAddress(formattedDefaultAddress);
      setLocation({ lat: 43.856098, lng: -79.337021 });
      localStorage.setItem("userAddress", formattedDefaultAddress);
      localStorage.setItem("lat", 43.856098);
      localStorage.setItem("lng", -79.337021);

      setAddressError("Oops! That's outside Markham. Using City Hall instead.");
      setErrorVisible(true);
      setDropdownVisible(false);
      lastValidAddressRef.current = formattedDefaultAddress;
    }
  };

  return {
    addressError,
    suggestions,
    handleChange,
    selectAddress,
    handleSubmit,
    handleBlur,
    setAddressError,
  };
};

export default useAddress;
