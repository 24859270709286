import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import RestaurantCard from "../RestaurantCard/RestaurantCard";
import "../ExploreTab/ExploreTab.css";
import { useSavedRestaurants, useSearch } from "../SavedRestaurantContext.jsx";
import Masonry from "react-masonry-css";
import { LocationContext } from "../../LocationContext.jsx";
import { useAuth } from "../AuthContext.jsx";

const Results = () => {
  const { location, loading } = useContext(LocationContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchTerm = params.get("query") || "";
  const { savedRestaurantIds, unsaveRestaurant, saveRestaurant } = useSavedRestaurants();
  const { fetchRecommendedRestaurants, searchResults, setSearchResults, setSearchError, searchFinalized, setSearchFinalized } = useSearch();
  const { isSignedIn } = useAuth();
  const [restaurants, setRestaurants] = useState([]); // Initialize restaurants state
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [delayFinished, setDelayFinished] = useState(false); // New state for delay handling
  const loadingRef = useRef(null);
  const limit = 20;

  useEffect(() => {
    console.log('Fetched searchResults in Results component:', searchResults);  // Log the search results
  }, [searchResults]);

  useEffect(() => {
    console.log("Setting restaurants with searchResults:", searchResults);  // Log the searchResults before setting restaurants
    setRestaurants(searchResults);  // Update the restaurants state with the searchResults
  }, [searchResults]);

  const loadRestaurants = async (reset = false) => {
    if (isAllLoaded || isLoading || loading || !searchFinalized) {
      console.log("Load Restaurants Blocked:", {
        isAllLoaded,
        isLoading,
        loading,
        searchFinalized,
      });
      return;
    }
    setIsLoading(true);
    setSearchInitiated(true);
    setSearchError(null);
    setDelayFinished(false);

    try {
      console.log("Fetching restaurants...");
      console.log("Location context value:", location); 
      console.log("Search term:", searchTerm);

      await fetchRecommendedRestaurants(searchTerm);

      console.log("Fetched searchResults:", searchResults);

      if (reset) {
        setRestaurants(searchResults);
        setOffset(0);
      } else {
        setRestaurants((prev) => [...prev, ...searchResults]);
      }

      if (searchResults.length < limit) {
        setIsAllLoaded(true);
      }
      setOffset((prev) => (reset ? limit : prev + limit));

      // Delay setting delayFinished to true to give time for state updates
      setTimeout(() => setDelayFinished(true), 50);
    } catch (error) {
      console.error("Failed to load restaurants", error);
      setSearchError("Failed to load restaurants. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Component Mounted:");
    console.log("searchTerm:", searchTerm);
    console.log("searchFinalized:", searchFinalized);

    // If there is a search term on mount, trigger the search
    if (searchTerm && !searchFinalized) {
      setSearchFinalized(true);
      loadRestaurants(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    console.log("Search Finalized:", searchFinalized);
    console.log("Search Term:", searchTerm);
    console.log("Location:", location);
  
    if (searchFinalized && !loading && searchTerm) {
      console.log("Search term finalized, fetching restaurants");
    
      // Clear restaurant list and reset pagination whenever search term changes
      setRestaurants([]);
      setIsAllLoaded(false);
      setOffset(0);
      loadRestaurants(true);
    }
  }, [searchTerm, location, loading, searchFinalized]);

  useEffect(() => {
    console.log("Restaurants:", restaurants);
    console.log("IsLoading:", isLoading);
  }, [restaurants, isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isAllLoaded && !isLoading) {
          loadRestaurants();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [isLoading, isAllLoaded]);

  const toggleSave = (restaurant) => {
    if (!isSignedIn) {
      alert("Please sign in to save restaurants.");
      return;
    }
    const isCurrentlySaved = savedRestaurantIds.includes(restaurant.restaurant_id);
    isCurrentlySaved
      ? unsaveRestaurant(restaurant.restaurant_id)
      : saveRestaurant(restaurant);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    480: 2,
  };

  return (
    <div className="explore-tab-content">
      {isLoading && restaurants.length === 0 ? (
        <div>Loading restaurants...</div>
      ) : !isLoading && restaurants.length === 0 && searchFinalized && delayFinished && searchInitiated === false ? (
        <div>No restaurants found.</div>
      ) : (
        <>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid-explore"
            columnClassName="my-masonry-grid_column-explore"
          >
            {restaurants.map((restaurant) => (
              <RestaurantCard
                key={restaurant.restaurant_id}
                restaurant={restaurant}
                onSave={() => toggleSave(restaurant)}
                isSaved={savedRestaurantIds.includes(restaurant.restaurant_id)}
              />
            ))}
          </Masonry>
          <div
            ref={loadingRef}
            style={{ height: "20px", margin: "10px 0" }}
          ></div>
          {isAllLoaded && <div>No more restaurants to load</div>}
        </>
      )}
    </div>
  );
};

export default Results;
