import React, { useEffect } from "react";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Modal = ({
  children,
  onClose,
  showCloseButton = true,
  closeOnEscape = true,
  closeOnOutsideClick = true,
}) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (closeOnEscape && event.key === "Escape") {
        try {
          onClose();
        } catch (error) {
          console.error("Error closing modal on escape key press:", error);
        }
      }
    };

    const handleOutsideClick = (event) => {
      if (closeOnOutsideClick && !event.target.closest(".modal-content")) {
        try {
          onClose();
        } catch (error) {
          console.error("Error closing modal on outside click:", error);
        }
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("mousedown", handleOutsideClick);
    document.body.style.overflow = "hidden"; // Prevent scrolling

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = ""; // Re-enable scrolling
    };
  }, [onClose, closeOnEscape, closeOnOutsideClick]);

  return (
    <div className="modal" role="dialog" aria-modal="true">
      <div className="modal-content" tabIndex={-1}>
        {showCloseButton && (
          <button onClick={() => {
            try {
              onClose();
            } catch (error) {
              console.error("Error closing modal on button click:", error);
            }
          }} className="close-button">
            <FontAwesomeIcon icon={faX} />
          </button>
        )}
        <button onClick={() => {
          try {
            onClose();
          } catch (error) {
            console.error("Error closing modal on back button click:", error);
          }
        }} className="back-button">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
