import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./OffersTab.css";
import { restaurantData } from "../../mockAPI";

const OffersTab = () => {
  const [visibleOffers, setVisibleOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const sentinelRef = useRef(null);

  useEffect(() => {
    // Initial load with filter for restaurants with offers
    const filteredData = restaurantData.filter(
      (restaurant) => restaurant.offers.length > 0
    );
    setVisibleOffers(filteredData.slice(0, 7));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMoreOffers();
        }
      },
      { threshold: 1.0 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => observer.disconnect();
  }, [visibleOffers, hasMore, isLoading]);

  const loadMoreOffers = () => {
    setIsLoading(true);
    const nextIndex = visibleOffers.length;
    const nextBatch = restaurantData
      .filter((restaurant) => restaurant.offers.length > 0)
      .slice(nextIndex, nextIndex + 7);

    if (nextBatch.length > 0) {
      setVisibleOffers((prev) => [...prev, ...nextBatch]);
    } else {
      setHasMore(false);
    }
    setIsLoading(false);
  };

  const handleOfferClick = (restaurantId) => {
    navigate(`/explore/restaurant/${restaurantId}`, {
      state: { defaultTab: "offers" },
    });
  };

  return (
    <div className="center-wrapper">
      <div className="offerstab-container">
        {visibleOffers.map((restaurant) => (
          <div
            key={restaurant.id}
            className="singleoffer-container"
            onClick={() => handleOfferClick(restaurant.id)}
          >
            <div className="singleoffer-img">
              <img src={restaurant.offerImage} alt="Offer" />
            </div>
            <div className="singleoffer-details-container">
              <div className="singleoffer-details">
                <div className="restaurant-names">{restaurant.name}</div>
                <div className="restaurant-info">
                  <span style={{ color: "#fb6f92" }}>{"\u2605"}</span> 3.9{" "}
                  {"\u2022"} American {"\u2022"} $$ {"\u2022"} 7.2km
                </div>
              </div>
              <button className="amountOffers">
                View {restaurant.offers.length}{" "}
                {restaurant.offers.length > 1 ? "Offers" : "Offer"}
              </button>
            </div>
          </div>
        ))}
        {isLoading && <div className="loading">Loading...</div>}
      </div>
      {!hasMore && (
        <div className="centered-message">No more offers to load</div>
      )}
      <div ref={sentinelRef} style={{ height: "20px" }}></div>
    </div>
  );
};

export default OffersTab;
