import React from 'react';
import './Dropdown.css';

const RecentSearches = ({ recentSearches, handleRecentSearchClick, clearRecentSearches }) => (
  <div className="dropdown-content">
    {recentSearches.map((search, index) => (
      <div key={index} className="dropdown-item" onClick={() => handleRecentSearchClick(search)}>
        {search}
      </div>
    ))}
    <div className="dropdown-item clear-searches" onClick={clearRecentSearches}>
      Clear Recent Searches
    </div>
  </div>
);

export default RecentSearches;
