import React, { useState } from "react";
import Logo from "../../Logo";
import AddressBar from "../AddressBar/AddressBar";
import SearchBar from "../SearchBar/SearchBar";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import "./NavBar.css";

const NavBar = () => {
    const [error, setError] = useState(null);

    return (
        <div className="navbar-container">
            {error ? (
                <div className="error-message">Something went wrong: {error.message}</div>
            ) : (
                <>
                    <div className="navbar-left">
                        <ErrorBoundary>
                            <Logo />
                        </ErrorBoundary>
                    </div>
                    <div className="navbar-center">
                        <ErrorBoundary>
                            <AddressBar />
                        </ErrorBoundary>
                    </div>
                    <div className="navbar-right">
                        <ErrorBoundary>
                            <SearchBar className="search-bar" />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <HamburgerMenu className="hamburger-menu" />
                        </ErrorBoundary>
                    </div>
                </>
            )}
        </div>
    );
};

const ErrorBoundary = ({ children }) => {
    try {
        return children;
    } catch (error) {
        console.error("Error rendering component:", error);
        return <div className="error-message">Failed to load component</div>;
    }
};

export default NavBar;
