import React, { useState, useEffect } from "react";
import RestaurantCard from "../RestaurantCard/RestaurantCard";
import "./SavedTab.css";
import { useSavedRestaurants } from "../SavedRestaurantContext";
import Masonry from "react-masonry-css"; // Importing the Masonry component
import Modal from "../Modal/Modal";
import { FaApple } from "react-icons/fa";
import GoogleIcon from "../../Assets/icons8-google.svg";
import { useAuth } from "../AuthContext"; // Use your custom AuthContext hook

const SavedTab = () => {
  const { savedRestaurantsDetails, unsaveRestaurant, loading: savedLoading } = useSavedRestaurants();
  const { isSignedIn, signIn, loading: authLoading } = useAuth(); // Destructure `user`
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [error, setError] = useState("");

  // Check local storage for user info to prevent re-fetching unnecessarily
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser || !authLoading) {
      setIsAuthChecked(true); // Auth check is done if user exists in local storage or if auth loading is complete
    }
  }, [authLoading]);

  // Control modal visibility based on sign-in status
  useEffect(() => {
    if (isAuthChecked && !isSignedIn) {
      setIsModalOpen(true); // Open modal if auth is checked, user is not signed in
    } else if (isAuthChecked && isSignedIn) {
      setIsModalOpen(false); // Close modal if user is signed in
    }
  }, [isSignedIn, isAuthChecked]);

  const toggleSaveRestaurant = async (restaurantId) => {
    try {
      await unsaveRestaurant(restaurantId);
    } catch (error) {
      console.error("Error unsaving restaurant:", error);
      setError("Failed to unsave restaurant. Please try again.");
    }
  };

  const handleSignIn = async () => {
    try {
      await signIn();
      setIsModalOpen(false);
      setError(""); // Clear any previous errors on successful sign-in
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Failed to sign in. Please try again.");
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    480: 2,
  };

  const isSingleCard = savedRestaurantsDetails.length === 1;

  // 1. Wait for `isAuthChecked` and ensure saved data is loaded before rendering
  if (!isAuthChecked || (isSignedIn && savedLoading)) {
    return (
      <div className="centered-message">
        <p>{isAuthChecked ? "Loading saved restaurant data..." : "Checking authentication status..."}</p>
      </div>
    );
  }

  // 2. If the user is not signed in, show the sign-in message and modal only after auth is fully checked
  if (isAuthChecked && !isSignedIn) {
    return (
      <div className="centered-message">
        <p>Please sign in to see your saved restaurants!</p>
        {isModalOpen && (
          <Modal onClose={() => setIsModalOpen(false)}>
            <div className="centered-message">
              <p>Sign in and find your favorite restaurants anytime!</p>
            </div>
            <button className="google-signin-button-saved" onClick={handleSignIn}>
              <img src={GoogleIcon} alt="Google" className="google-signin-icon" />
              Sign In with Google
            </button>
            <button className="apple-signin-button-saved" onClick={handleSignIn}>
              <FaApple className="apple-signin-icon" /> Sign In with Apple
            </button>
            <div className="terms">
              <p>By signing in, you agree to our Terms of Use and Privacy Policy</p>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  // 3. Finally, render the saved restaurants once both authentication and data are ready
  return (
    <div className={`saved-tab-content ${isSingleCard ? "single-card" : ""}`}>
      {error && <div className="error-message">{error}</div>}
      {savedRestaurantsDetails.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid-saved"
          columnClassName="my-masonry-grid_column-saved"
        >
          {savedRestaurantsDetails.map((restaurant) => (
            <RestaurantCard
              key={restaurant.restaurant_id}
              restaurant={restaurant}
              onSave={() => toggleSaveRestaurant(restaurant.restaurant_id)}
              isSaved={true}
              isSignedIn={isSignedIn} // Updated prop to isSignedIn
            />
          ))}
        </Masonry>
      ) : (
        <p className="centered-content">Save your favourite restaurants here!</p>
      )}
    </div>
  );
};

export default SavedTab;
