import React, { useState } from "react";
import Modal from "../Modal/Modal";
import "./Offers.css";

const Offers = ({ offers }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track the index of the expanded offer

  const handleOpenModal = (offer) => {
    setSelectedOffer(offer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null); // Reset modal content on close
  };

  const handleRedeemClick = (e) => {
    e.stopPropagation(); // Prevents the modal for the offer from opening
    const randomCode = Math.floor(100000 + Math.random() * 900000); // Generates a 6 digit random number
    setModalContent(`Your redeem code is: ${randomCode}`);
    setIsModalOpen(true);
  };

  const toggleExpansion = (index, e) => {
    e.stopPropagation(); // Prevent click from bubbling up to parent elements
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle expansion or collapse
  };

  if (!offers) {
    return <div>Loading offers...</div>;
  }

  if (offers.length === 0) {
    return <div>No offers available at this time.</div>;
  }

  return (
    <div className="offers-wrapper">
      {offers.map((offer, index) => (
        <div key={index} className="offers">
          <div className="offers-left">
            <img
              src={offer.image}
              alt={`Offer ${index + 1}`}
              onClick={() => handleOpenModal(offer)}
            />
          </div>
          <div className="offers-right">
            <div className="offers-right-details">
              <div className="details-and-button">
                <div className="offer-details">
                  <div className="offers-right-details-text">
                    {offer.detail}
                  </div>
                  <div className="offers-right-details-expiry">
                    {offer.expiry}
                  </div>
                </div>
                <button
                  className="redeem"
                  onClick={(e) => handleRedeemClick(e)}
                >
                  Redeem Offer
                </button>
              </div>
              <div
                className="offers-right-see-details"
                onClick={(e) => toggleExpansion(index, e)}
              >
                See Details +
              </div>
            </div>
            <div
              className={`offers-right-expanded-content ${
                expandedIndex === index ? "expanded" : ""
              }`}
            >
              <p>More detailed information about the offer...</p>
              <p>Terms and conditions...</p>
              <p>Additional promotions or discounts...</p>
            </div>
          </div>
        </div>
      ))}

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className="centered-modal-content">
            {modalContent || (
              <img src={selectedOffer?.image} alt="Selected Offer" />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Offers;
