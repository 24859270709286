import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [interactionCount, setInteractionCount] = useState(null);

  const signIn = async () => {
    try {
      const popup = window.open(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/auth/google`,
        "GoogleSignIn",
        "width=500,height=600"
      );

      if (!popup) throw new Error("Popup blocked");

      const popupInterval = setInterval(() => {
        if (popup.closed) {
          clearInterval(popupInterval);
          fetchUserData(); // Fetch user data after the popup is closed
        }
      }, 500);
    } catch (err) {
      console.error("Failed to sign in:", err);
      setError("Failed to sign in. Please try again.");
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/auth/status`,
        {
          withCredentials: true,
        }
      );
      setUser(response.data.user);
      setAccessToken(response.data.accessToken);
      setError(null);
      await fetchInteractionCount();

      // Save the user state in local storage
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("accessToken", response.data.accessToken);
    } catch (err) {
      console.error("Failed to fetch user data:", err);

      if (err.response && err.response.status === 401) {
        setUser(null);
        setAccessToken(null);
        setError("Unauthorized. Please sign in.");
      } else {
        setError("Failed to fetch user data. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchInteractionCount = async () => {
    try {
      console.log("Fetching interaction count...");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/getFirstVisitTags`,
        {},
        {
          withCredentials: true,
        }
      );

      if (response.data.message === "User preference set") {
        setInteractionCount(true);
      } else {
        setInteractionCount(false);
      }
    } catch (err) {
      console.error("Failed to fetch interaction count:", err);
      setError("Failed to fetch interaction count. Please try again.");
      setInteractionCount(null);
    }
  };

  const signOut = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      setUser(null);
      setAccessToken(null);
      setError(null);

      // Clear user data from local storage on sign out
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
    } catch (err) {
      console.error("Failed to sign out:", err);
      setError("Failed to sign out. Please try again.");
    }
  };

  useEffect(() => {
    const handleAuthMessage = async (event) => {
      if (event.origin !== process.env.REACT_APP_BACKEND_PORT_URL) return;

      const { user, accessToken } = event.data;

      setUser(user);
      setAccessToken(accessToken);
      setError(null);
      setLoading(false);

      try {
        const storedData =
          JSON.parse(localStorage.getItem("restaurantData")) || [];

        if (storedData.length > 0) {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_PORT_URL}/api/insertlocalstoragedata`,
            { interactions: storedData },
            { withCredentials: true }
          );

          if (response.status === 200) {
            localStorage.removeItem("restaurantData");
          }
        }

        const interactionResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_PORT_URL}/api/getFirstVisitTags`,
          {},
          { withCredentials: true }
        );
        setInteractionCount(
          interactionResponse.data.message === "User preference set"
        );
      } catch (error) {
        console.error(
          "Failed to sync local storage data or fetch interaction count:",
          error
        );
      }
    };

    window.addEventListener("message", handleAuthMessage);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, []);

  useEffect(() => {
    // Load user from local storage on initial mount
    const storedUser = localStorage.getItem("user");
    const storedAccessToken = localStorage.getItem("accessToken");

    if (storedUser && storedAccessToken) {
      setUser(JSON.parse(storedUser));
      setAccessToken(storedAccessToken);
    } else {
      fetchUserData(); // Fetch user data if not in local storage
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        accessToken,
        signIn,
        signOut,
        isSignedIn: !!user,
        loading,
        error,
        interactionCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AppProvider = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};
