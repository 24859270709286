import React from "react";
import DOMPurify from "dompurify";

const AddressInput = ({
  tempAddress,
  handleChange,
  handleKeyDown,
  handleBlur,
  handleFocus,
  inputFieldRef,
  dropdownVisible,
  errorVisible,
  setAddressError, // Receive setAddressError as a prop
}) => {
  const validateInput = (value) => {
    const minLength = 3;
    const forbiddenCharacters = /[^a-zA-Z0-9\s,.-]/;

    if (forbiddenCharacters.test(value)) {
      return "Address contains invalid characters.";
    }

    if (value.length >= 1 && value.length < minLength) {
      return "Address must be at least 3 characters long.";
    }

    return "";
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setAddressError(""); // Clear previous address error
    handleChange(sanitizedValue);
  };

  const handleInputBlur = (event) => {
    const { value } = event.target;
    const error = validateInput(value);
    if (error) {
      setAddressError(error); // Set address error
      console.error("Validation error:", error);
    }
    handleBlur();
  };

  return (
    <div>
      <input
        ref={inputFieldRef}
        className={`address-input ${dropdownVisible || errorVisible ? "no-radius active" : "radius"}`}
        type="text"
        value={tempAddress}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          try {
            handleKeyDown(e);
          } catch (error) {
            console.error("Error handling key down:", error);
          }
        }}
        onBlur={handleInputBlur}
        onFocus={() => {
          try {
            handleFocus();
          } catch (error) {
            console.error("Error handling focus:", error);
          }
        }}
        placeholder="Enter your address"
      />
    </div>
  );
};

export default AddressInput;
