import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

const Logo = () => {
  try {
    return (
      <div>
        <Link to="/explore" className="logo">
          <h1>Ophelia</h1>
        </Link>
      </div>
    );
  } catch (error) {
    console.error("Error rendering Logo component:", error);
    return <div className="error-message">Failed to load logo</div>;
  }
};

export default Logo;
