import React from 'react';
import './Dropdown.css';
import { useNavigate } from 'react-router-dom';

const SearchResults = ({ searchResults, searchTerm, onResultClick }) => {
  const navigate = useNavigate();

  const handleResultClick = (result) => {
    navigate(`/explore/restaurant/${result.id}`);
    onResultClick(); // Call the function to hide the dropdown and clear the search term
  };

  const handleSearchClick = () => {
    if (searchTerm) {
      onResultClick();  // Hide the dropdown and clear the search term
      navigate(`/results?query=${encodeURIComponent(searchTerm)}`);  // Navigate to the results page
    } else {
      // Optionally, you can handle the error here or pass a setSearchError function from the parent
      console.error("Please enter a valid search term.");
    }
  };

  return (
    <div className="dropdown-content">
      {searchResults.slice(0, 6).map((result, index) => (
        <div key={index} className="dropdown-item" onClick={() => handleResultClick(result)}>
          {result.name}
        </div>
      ))}
      {searchTerm && (
        <div className="dropdown-item search-item" onClick={handleSearchClick}>
          Search "{searchTerm}"...
        </div>
      )}
    </div>
  );
};

export default SearchResults;
