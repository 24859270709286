import React, { useEffect } from "react";
import { FaLocationArrow } from "react-icons/fa";

const AddressDropdown = ({
  dropdownVisible,
  recentAddresses,
  selectAddress,
  fetchCurrentLocation,
  dropdownRef,
  suggestions,
  selectSuggestion,
}) => {
  useEffect(() => {
    const button = dropdownRef.current?.querySelector("li:first-child"); // Select the first <li> (Use Current Location)
    const suggestionListItems = dropdownRef.current?.querySelectorAll(
      "li:not(:first-child)"
    ); // Select other <li> elements (suggestions)

    if (button) {
      button.addEventListener("click", (e) => {
        e.stopPropagation();
        fetchCurrentLocation();
      });
    }

    if (suggestionListItems) {
      suggestionListItems.forEach((item) => {
        item.addEventListener("click", (e) => {
          e.stopPropagation();
          selectSuggestion(item.textContent);
        });
      });
    }

    // Clean up when the component unmounts
    return () => {
      if (button) {
        button.removeEventListener("click", fetchCurrentLocation);
      }

      if (suggestionListItems) {
        suggestionListItems.forEach((item) => {
          item.removeEventListener("click", selectSuggestion);
        });
      }
    };
  }, [suggestions, recentAddresses]);

  return (
    <ul
      className={`address-dropdown ${dropdownVisible ? "active" : ""}`}
      ref={dropdownRef}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <li onMouseDown={(e) => {
        e.stopPropagation();
        fetchCurrentLocation();
      }}>
        <FaLocationArrow className="location-icon" />
        Use Current Location
      </li>
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          onMouseDown={(e) => {
            e.stopPropagation();
            selectSuggestion(suggestion.description);
          }}
        >
          {suggestion.description}
        </li>
      ))}
      {recentAddresses.map((address, index) => (
        <li
          key={index}
          onMouseDown={(e) => {
            e.stopPropagation();
            selectAddress(address);
          }}
        >
          {address}
        </li>
      ))}
    </ul>
  );
};

export default AddressDropdown;
