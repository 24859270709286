import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faClock,
  faPhone,
  faStar,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import "./Info.css";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Info = ({
  restaurantName,
  latitude,
  longitude,
  rating,
  totalReviews,
  affordability,
  tags,
  workingHours,
  contact,
  address,
  distance,
}) => {
  const [showHours, setShowHours] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [mapCoords, setMapCoords] = useState(null);
  const [error, setError] = useState(null);

  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    searchAddress(address);
  }, [address]);

  const searchAddress = async (address, retries = 3) => {
    
    for (let i = 0; i < retries; i++) {
      try {
        const results = await provider.search({ query: address });
        
        if (results && results.length > 0) {
          setMapCoords({ lat: results[0].y, lng: results[0].x });
          return; // Exit the function if successful
        } else {
          
          setMapCoords(null); // Reset or handle accordingly
          return;
        }
      } catch (error) {
        console.error("Error fetching geocode:", error);
        if (i === retries - 1) {
          console.error("Max retries reached. Failed to fetch geocode.");
        }
      }
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${suffix}`;
  };

  const sortWorkingHours = (hours) => {
    const order = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return hours.sort(
      (a, b) => order.indexOf(a.day_of_week) - order.indexOf(b.day_of_week)
    );
  };

  const formatWorkingHours = (hours) => {
    const sortedHours = sortWorkingHours(hours);
    return sortedHours.map((hour) => {
      if (hour.is_closed) {
        return `${hour.day_of_week}: Closed`;
      }
      const openTime = formatTime(hour.opening_time);
      const closeTime = formatTime(hour.closing_time);
      return `${hour.day_of_week}: ${openTime} - ${closeTime}`;
    });
  };

  const formattedWorkingHours = formatWorkingHours(workingHours);

  const getCurrentStatus = () => {
    const now = new Date();
    const currentDay = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = now.toTimeString().split(" ")[0];

    const todayHours = workingHours.find(
      (hour) => hour.day_of_week === currentDay
    );

    if (!todayHours || todayHours.is_closed) {
      return "Closed today";
    }

    const closingTime = todayHours.closing_time;
    if (currentTime < todayHours.opening_time || currentTime >= closingTime) {
      return "Closed";
    }

    return `Open until ${formatTime(closingTime)}`;
  };

  const handleLinkClick = () => {
    const detailedAddress = `${restaurantName}, ${formatAddress(address)}`;
    const coords = `@${latitude},${longitude}`;
    const query = `${encodeURIComponent(detailedAddress)}${coords}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`;
    window.open(url, "_blank");
  };

  const formatAddress = (address) => {
    // Example to remove the postal code and country
    const parts = address.split(", ");
    if (parts.length > 2) {
      return parts.slice(0, -2).join(", ");
    }
    return address;
  };

  const formatTags = (tags) => {
    return tags
      .map((tag) => {
        const subTags = tag.sub_tags.join(" · ");
        return `${tag.main_tag}${subTags ? " · " + subTags : ""}`;
      })
      .join(" · ");
  };

  return (
    <div className="info-wrapper">
      {error && <div className="error-message">{error}</div>}
      <div className="restaurant-tags">
        <div className="restaurant-tags-top">{restaurantName}</div>
        <div className="restaurant-tags-bottom">
          {formatTags(tags)} · {"$".repeat(affordability)} · {distance}
        </div>
      </div>
      <div className="address-section">
        <div className="address">
          <div className="icon">
            <FontAwesomeIcon icon={faMap} />
          </div>
          <div
            className="address-text-container"
            onClick={() => setShowMap(!showMap)}
          >
            <div className="address-text">{formatAddress(address)}</div>
            <button className="expand-btn">{showMap ? "-" : "+"}</button>
          </div>
        </div>
        <div
          className={`map-container ${showMap ? "expanded" : ""}`}
          onClick={handleLinkClick}
        >
          {true && (
            <MapContainer
              center={[latitude, longitude]}
              zoom={13}
              style={{ height: "200px", width: "80%", borderRadius: "20px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[latitude, longitude]} />
            </MapContainer>
          )}
        </div>
      </div>
      <div className="business-hours-section">
        <div className="business-hours">
          <div className="icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div
            className="business-hours-text-container"
            onClick={() => setShowHours(!showHours)}
          >
            <div className="business-hours-text">{getCurrentStatus()}</div>
            <button className="expand-btn">{showHours ? "-" : "+"}</button>
          </div>
        </div>
        <div className={`weekly-hours ${showHours ? "expanded" : ""}`}>
          {formattedWorkingHours.map((hour, index) => (
            <div key={index} className="hour-entry">
              <span className="day-name">{hour.split(": ")[0]}:</span>
              <span className="hour-time">{hour.split(": ")[1]}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="phone">
        <div className="icon">
          <FontAwesomeIcon icon={faPhone} />
        </div>
        <div
          className="phone-text-container"
          onClick={() => (window.location.href = `tel:${contact}`)}
        >
          <div className="phone-text">{contact}</div>
          <button className="expand-btn">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </button>
        </div>
      </div>
      <div className="ratings">
        <div className="icon">
          <FontAwesomeIcon icon={faStar} />
        </div>
        <div className="ratings-text-container" onClick={handleLinkClick}>
          <div className="ratings-text">
            {rating} ({totalReviews})
          </div>
          <button className="expand-btn">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Info;
