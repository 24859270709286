const generateRestaurants = () => {
    const restaurants = [];
  
    for (let i = 1; i <= 50; i++) {
      const offersCount = Math.floor(Math.random() * 4); // Generates a random number between 0 and 3
      const offers = [];
  
      for (let j = 1; j <= offersCount; j++) {
        offers.push({
          id: j,
          detail: `2 Can Dine for $19.99 is Back at Swiss Chalet`,
          image: `https://via.placeholder.com/400x400`, // Assuming each offer has a unique image
          expiry: `Expires on ${(new Date()).toDateString()}`, // Example expiry date
        });
      }
  
      restaurants.push({
        id: i,
        name: `Restaurant ${i}`,
        description: "\u2605 3.9 \u2022 American \u2022 $$ \u2022 7.2km",
        offerImage: `https://via.placeholder.com/100`, // General offer image for the restaurant
        offers: offers,
      });
    }
  
    return restaurants;
  };
  
  export const restaurantData = generateRestaurants();
  