import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import PasswordPage from "./PasswordPage.jsx";
import { AppProvider } from "./Components/AuthContext.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <AppProvider>
      <PasswordPage />
    </AppProvider>
  
);
