import React from 'react';
import "./Privacy.css"

const Privacy = ({ onClose }) => {
    return (
      <div className='privacy-conatiner'>
        Privacy
        
      </div>
    );
  };

export default Privacy;
