import * as turf from "@turf/turf";
import axios from "axios";
import BOUNDARY_POLYGON from "../../Assets/Markham.json";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const geocodeCoordinates = async (lat, lon) => {
  try {
    // Validate latitude and longitude
    if (typeof lat !== 'number' || typeof lon !== 'number') {
      throw new Error('Latitude and Longitude must be numbers');
    }

    if (lat < -90 || lat > 90) {
      throw new Error('Latitude must be between -90 and 90');
    }

    if (lon < -180 || lon > 180) {
      throw new Error('Longitude must be between -180 and 180');
    }

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${GOOGLE_API_KEY}`
    );
    const data = await response.json();
    if (data.status === "OK" && data.results[0]) {
      return data.results[0].formatted_address;
    }
  } catch (error) {
    console.error("Error geocoding coordinates:", error);
    return "Unknown location";
  }
};

export const fetchAddressDetails = async (address) => {
  try {
    console.log(`Fetching address details for: ${address}`); // Log input address

    const encodedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${GOOGLE_API_KEY}`;

    const response = await fetch(url);
    const data = await response.json();
    console.log('Address details API response:', data); // Log API response

    if (data.status === "OK" && data.results[0]) {
      return data.results[0];
    } else {
      console.error("Error in fetchAddressDetails response:", data); // Log error response
      return null;
    }
  } catch (error) {
    console.error("Error fetching address details:", error); // Log error
    return null;
  }
};

export const fetchAutocompleteSuggestions = async (inputAddress) => {
  if (inputAddress.length < 4) {
    return [];
  }
  try {
    console.log(`Fetching autocomplete suggestions for: ${inputAddress}`); // Log input address

    const response = await axios.get(`${process.env.REACT_APP_BACKEND_PORT_URL}/autocomplete`, {
      params: { input: inputAddress }
    });
    const data = response.data;
    console.log('Autocomplete API response:', data); // Log API response

    if (data.status === "OK") {
      const formattedSuggestions = data.predictions
        .slice(0, 4)
        .map((suggestion) => ({
          ...suggestion,
          description: formatSuggestion(suggestion.description), // Format the suggestion
        }));
      return formattedSuggestions;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching autocomplete suggestions:', error); // Log error
    return [];
  }
};

const formatSuggestion = (suggestion) => {
  // Split the suggestion string into its components
  const addressComponents = suggestion.split(", ");

  // Extract street number and name
  const streetPart = addressComponents.slice(0, 2).join(", ").trim();

  // Initialize cityPart to an empty string
  let cityPart = "";

  // Loop through address components to find city and avoid duplication
  for (let i = 2; i < addressComponents.length; i++) {
    const component = addressComponents[i].trim();
    if (
      component.toUpperCase() === "ON" ||
      component.toUpperCase() === "ONTARIO"
    ) {
      break;
    }
    cityPart = component;
  }

  // Combine the street and city parts (only if a city part was found)
  return cityPart ? `${streetPart}, ${cityPart}` : streetPart;
};

export const validateAddressWithinBoundary = async (address) => {
  try {
    console.log(`Validating address within boundary: ${address}`); // Log input address

    const addressDetails = await fetchAddressDetails(address);
    console.log('Address details:', addressDetails); // Log address details

    if (
      !addressDetails ||
      !addressDetails.geometry ||
      !addressDetails.geometry.location
    ) {
      return null; // Return null for invalid address details
    }

    const { lat, lng } = addressDetails.geometry.location;
    const point = turf.point([lng, lat]);

    const polygon = BOUNDARY_POLYGON.features[0];
    const isWithin = turf.booleanPointInPolygon(point, polygon);
    console.log('Is within boundary:', isWithin); // Log boundary check result

    if (isWithin) {
      return {
        address: addressDetails.formatted_address,
        lat: lat,
        lng: lng
      }; // Return object with address and coordinates
    } else {
      return null; // Return null for address outside boundary
    }
  } catch (error) {
    console.error('Error validating address within boundary:', error); // Log error
    return null; // Return null for unexpected errors
  }
};
