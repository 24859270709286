import React from 'react';
import "./Terms.css"

const Terms = ({ onClose }) => {
    return (
      <div className='terms-container'>
        Terms
        
      </div>
    );
  };
export default Terms;
