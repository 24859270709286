import React, { useState } from "react";
import Modal from "../Modal/Modal.jsx";
import "./PopupModal.css";

const PopupModal = ({ onClose }) => {
  const categories = ["Chinese", "Japanese", "Korean", "American"];
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [error, setError] = useState(null);

  const handleCheckboxChange = (category) => {
    try {
      setSelectedCategories((prev) =>
        prev.includes(category)
          ? prev.filter((c) => c !== category)
          : [...prev, category]
      );
    } catch (err) {
      console.error("Error handling checkbox change:", err);
      setError("An error occurred while updating the selection.");
    }
  };

  const handleSubmit = () => {
    try {
      if (selectedCategories.length >= 3) {
        // Store selected categories in local storage as preferences
        const storedData = JSON.parse(localStorage.getItem("restaurantData")) || [];

        selectedCategories.forEach((category) => {
          storedData.push({
            mainCuisine: category,
            interaction_type: "preference",
          });
        });

        localStorage.setItem("restaurantData", JSON.stringify(storedData));

        onClose(true); // Assume profile completed
      } else {
        alert("Please select at least 3 categories.");
      }
    } catch (err) {
      console.error("Error handling form submission:", err);
      setError("An error occurred while submitting the form.");
    }
  };

  return (
    <Modal
      onClose={() => onClose(false)}
      showCloseButton={false}
      closeOnEscape={false}
      closeOnOutsideClick={false}
    >
      <div className="popupmodal-h2">Select your favorite food categories</div>
      {error && <div className="error-message">{error}</div>}
      <div className="categories">
        {categories.map((category) => (
          <label key={category} className="category-item">
            <input
              type="checkbox"
              checked={selectedCategories.includes(category)}
              onChange={() => handleCheckboxChange(category)}
            />
            {category}
          </label>
        ))}
      </div>
      <div className="button-group">
        <button className="skip" onClick={() => onClose(false)}>
          Skip
        </button>
        <button
          className={`lets-go ${selectedCategories.length < 3 ? "disabled" : ""}`}
          onClick={handleSubmit}
          disabled={selectedCategories.length < 3}
        >
          {selectedCategories.length < 3 ? "Select at least 3" : "Let's Go"}
        </button>
      </div>
    </Modal>
  );
};

export default PopupModal;
