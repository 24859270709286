import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FaApple } from "react-icons/fa";
import DarkModeContext from "../../DarkModeContext";
import { useSavedRestaurants } from "../SavedRestaurantContext";
import "./HamburgerMenu.css";
import { useNavigate } from "react-router-dom";
import PopupModal from "../PopupModal/PopupModal.jsx";
import GoogleIcon from "../../Assets/icons8-google.svg";
import { NavLink } from "react-router-dom";
import { useAuth } from "../AuthContext"; 

const HamburgerMenu = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSidebarPopupVisible, setIsSidebarPopupVisible] = useState(false);
  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const { isSignedIn, signIn, signOut, user, interactionCount } = useAuth(); 
  const { clearSavedRestaurants } = useSavedRestaurants();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handlePopupOpen = () => {
    console.log("Opening sidebar popup...");
    setIsSidebarPopupVisible(true);
    setShowSidebar(false);
  };

  const handlePopupClose = (profileCompleted) => {
    console.log("Closing sidebar popup...");
    setIsSidebarPopupVisible(false);
    localStorage.setItem("profileComplete", profileCompleted ? "true" : "false");
  };

  const handleSignIn = async () => {
    try {
      console.log("Attempting to sign in with Google OAuth...");
      await signIn(); 
      console.log("After signIn");
      setShowSidebar(false);
    } catch (error) {
      console.error("Sign-in failed:", error);
      alert("Sign-in failed. Please try again.");
    }
  };

  const handleSignOut = () => {
    try {
      signOut();
      clearSavedRestaurants();
      setShowSidebar(false);
    } catch (error) {
      console.error("Sign-out failed:", error);
      alert("Sign-out failed. Please try again.");
    }
  };

  const handleModeToggle = () => {
    try {
      setDarkMode(!darkMode);
    } catch (error) {
      console.error("Error toggling dark mode:", error);
    }
  };

  const handleNavigate = (path) => {
    try {
      navigate(path);
      setShowSidebar(false);
    } catch (error) {
      console.error(`Error navigating to ${path}:`, error);
    }
  };

  useEffect(() => {
    if (showSidebar) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [showSidebar, isSignedIn]);

  useEffect(() => {
    if (interactionCount !== null && interactionCount < 40 && localStorage.getItem("profileComplete") !== "true" && localStorage.getItem("firstvisit")) {
      console.log("Setting sidebar popup visible...");
      setIsSidebarPopupVisible(true);
    } else {
      console.log("Not setting sidebar popup visible.");
    }
  }, [interactionCount]);

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <button className="hamburger" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      {isSidebarPopupVisible && <PopupModal onClose={handlePopupClose} />}
      <div className={`sidebar${showSidebar ? " active" : ""}`}>
        {!isSignedIn ? (
          <>
            <button
              id="google-signin-button"
              className="google-signin-button-sidebar"
              onClick={handleSignIn}
            >
              <img
                src={GoogleIcon}
                alt="Google"
                className="google-signin-icon"
              />
              Sign In with Google
            </button>
            <button
              className="apple-signin-button-sidebar"
              onClick={handleSignIn}
            >
              <FaApple className="apple-signin-icon" /> Sign In with Apple
            </button>
          </>
        ) : (
          <div className="profile">
            <p>Welcome {user?.given_name || "User"}!</p>
            <button className="signout" onClick={handleSignOut}>
              Sign Out
            </button>
          </div>
        )}

        <div className="sidebar-nosocial">
          <div className="sidebar-top">
            {localStorage.getItem("profileComplete") !== "true" && (
              <div className="profile-setup" onClick={handlePopupOpen}>
                Complete Your Profile
              </div>
            )}
            <NavLink
              to="/feedback"
              className="feedback"
              onClick={() => handleNavigate("/feedback")}
            >
              <div>Feedback</div>
            </NavLink>
            <NavLink
              to="/partner"
              className="partner"
              onClick={() => handleNavigate("/partner")}
            >
              <div>Join Ophelia</div>
            </NavLink>
          </div>
          <div className="sidebar-footer">
            <NavLink
              to="/privacy"
              className="privacy"
              onClick={() => handleNavigate("/privacy")}
            >
              <button className="bottom-button">Privacy</button>
            </NavLink>
            <NavLink
              to="/terms"
              className="term"
              onClick={() => handleNavigate("/terms")}
            >
              <button className="bottom-button">Terms</button>
            </NavLink>
            <div>Copyright {currentYear}</div>
          </div>
        </div>
      </div>
      {showSidebar && <div className="overlay" onClick={toggleSidebar} />}
    </div>
  );
};

export default HamburgerMenu;
