import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { FaApple } from "react-icons/fa";
import GoogleIcon from "../../Assets/icons8-google.svg";
import Modal from "../Modal/Modal";
import ClampLines from "react-clamp-lines";
import "./RestaurantCard.css";
import { useAuth } from "../AuthContext"; // Use your custom AuthContext hook

const RestaurantCard = memo(({ restaurant, onSave, isSaved }) => {
  const navigate = useNavigate();
  const { isSignedIn, signIn } = useAuth(); // Use isSignedIn and signIn from AuthContext
  const [saveError, setSaveError] = useState("");
  const [randomHeight, setRandomHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    function calculateHeight() {
      const minHeight = windowWidth <= 480 ? 300 : 450;
      const maxHeight = windowWidth <= 480 ? 450 : 600;
      return Math.random() * (maxHeight - minHeight) + minHeight;
    }

    setRandomHeight(calculateHeight());

    function handleResize() {
      setWindowWidth(window.innerWidth);
      setRandomHeight(calculateHeight());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const handleNavigateToDetail = (e) => {
    if (!isModalOpen) {
      try {
        navigate(`/explore/restaurant/${restaurant.restaurant_id}`);
      } catch (error) {
        console.error("Failed to navigate to restaurant detail:", error);
      }
    }
  };

  const handleBookmarkClick = async (e) => {
    e.stopPropagation();
    if (!isSignedIn) {  
      setIsModalOpen(true);
      return;
    }
    try {
      await onSave(restaurant);      
      setSaveError("");         
    } catch (error) {
      console.error("Failed to save/unsave the restaurant", error);
      setSaveError("Failed to save. Please try again.");
    }
  };

  const handleSignIn = async () => {
    try {
      await signIn();  // Execute the sign-in process
      setIsModalOpen(false);  // Close the modal after signing in
      setSaveError("");  // Clear any errors
    } catch (error) {
      console.error("Error signing in:", error);
      setSaveError("Failed to sign in. Please try again.");
    }
  };

  function truncateByCharacters(text, maxChars) {
    if (typeof text !== 'string') {
      return '';
    }
    return text.length > maxChars ? text.substring(0, maxChars) + "..." : text;
  }

  const maxCharacters = 25;
  const truncatedText = truncateByCharacters(
    restaurant.name,
    maxCharacters
  );

  const {
    name,
    rating,
    distance,
    affordability,
    photos,
    tags,
  } = restaurant;

  const defaultImage = "https://10619-2.s.cdn12.com/rests/original/503_504917217.jpg";

  const imageUrl = photos && photos.length > 0 ? photos[0] : defaultImage;

  const mainTags = tags?.[0]?.main_tag || null;

  return (
    <div
      className="restaurant-card-container"
      onClick={handleNavigateToDetail}
      style={{ height: `${randomHeight}px` }}
    >
      <div className="restaurant-card-image">
        <img
          src={imageUrl}
          onError={(e) => {
            console.error("Failed to load image, using default:", e);
            e.target.src = defaultImage;
          }}
          alt={name}
          loading="lazy"
          className="lazy-load-img"
        />
      </div>
      <div className="restaurant-card-details-top">
        <div className="restaurant-card-details-top-name">
          <ClampLines
            text={truncatedText}
            id="custom-clamp"
            lines={1}
            ellipsis="..."
            buttons={false}
            innerElement="div"
          />
        </div>

        <div className="restaurant-card-details-top-bookmark">
          <FontAwesomeIcon
            icon={isSaved ? fasHeart : farHeart}
            onClick={handleBookmarkClick}
            style={isSaved ? { color: "#fb6f92" } : {}}
          />
        </div>
      </div>
      {saveError && <p className="error-message">{saveError}</p>}
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <>
            <div className="centered-message">
              <p>Sign in and find your favorite restaurants anytime!</p>
            </div>
            <button className="google-signin-button-saved" onClick={handleSignIn}>
              <img
                src={GoogleIcon}
                alt="Google"
                className="google-signin-icon"
              />
              Sign In with Google
            </button>
            <button className="apple-signin-button-saved">
              <FaApple className="apple-signin-icon" /> Sign In with Apple
            </button>
            <div className="terms">
              By signing in, you agree to our Terms of Use and Privacy Policy
            </div>
          </>
        </Modal>
      )}
      <div className="restaurant-card-details-bottom">
        <div>
          <span style={{ color: "#fb6f92" }}>{"\u2605"}</span> {rating}{" "}
          {"\u2022"} {mainTags} {"\u2022"} {"$".repeat(affordability)} {"\u2022"} {distance}
        </div>
      </div>
    </div>
  );
});

export default RestaurantCard;
