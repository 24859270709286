import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar.jsx";
import DarkModeContext from "./DarkModeContext";
import TabContainer from "./Components/TabContainer/TabContainer.jsx";
import ExploreTab from "./Components/ExploreTab/ExploreTab.jsx";
import SavedTab from "./Components/SavedTab/SavedTab.jsx";
import OffersTab from "./Components/OffersTab/OffersTab.jsx";
import RestaurantCardDetails from "./Components/RestaurantCardDetails/RestaurantCardDetails.jsx";
import Results from "./Components/Results/Results.jsx";
import Feedback from "./Components/Feedback/Feedback.jsx";
import Partner from "./Components/Partner/Partner.jsx";
import Privacy from "./Components/Privacy/Privacy.jsx";
import Terms from "./Components/Terms/Terms.jsx";
import { SavedRestaurantsProvider } from "./Components/SavedRestaurantContext.jsx";
import { LocationProvider } from "./LocationContext.jsx";

function App() {
   const [darkMode, setDarkMode] = useState(() => {
    try {
      const savedPreference = localStorage.getItem("darkMode");
      return savedPreference ? JSON.parse(savedPreference) : false;
    } catch (error) {
      console.error("Failed to retrieve dark mode preference:", error);
      return false;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem("darkMode", JSON.stringify(darkMode));
    } catch (error) {
      console.error("Failed to save dark mode preference:", error);
    }
  }, [darkMode]);

  return (
    <LocationProvider>
      <SavedRestaurantsProvider>
        <Router>
          <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
            <div className={darkMode ? "dark-mode" : ""}>
              <div className="app-container">
                <NavBar />
                <Routes>
                  <Route path="/" element={<Navigate replace to="/explore" />} />
                  <Route path="/" element={<TabContainer />}>
                    <Route index element={<Navigate replace to="/explore" />} />
                    <Route path="explore" element={<ExploreTab />} />
                    <Route path="saved" element={<SavedTab />} />
                    <Route path="offers" element={<OffersTab />} />
                    <Route path="results" element={<Results />} /> 
                  </Route>
                  <Route path="explore/restaurant/:restaurantId" element={<RestaurantCardDetails />} />
                  <Route path="feedback" element={<Feedback />} />
                  <Route path="partner" element={<Partner />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="*" element={<Navigate replace to="/explore" />} />
                </Routes>
              </div>
            </div>
          </DarkModeContext.Provider>
        </Router>
      </SavedRestaurantsProvider>
    </LocationProvider>
  );
}

export default App;
