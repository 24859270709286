import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext"; // Import the useAuth hook
import { LocationContext } from "../LocationContext"; // Import the LocationContext for lat/lng

const SavedRestaurantsContext = createContext();
const SearchContext = createContext();

export const useSavedRestaurants = () => useContext(SavedRestaurantsContext);
export const useSearch = () => useContext(SearchContext);

export const SavedRestaurantsProvider = ({ children }) => {
  const { user, accessToken } = useAuth(); // Get user and accessToken from AuthContext
  const { location } = useContext(LocationContext); // Get lat/lng from LocationContext
  const [savedRestaurantIds, setSavedRestaurantIds] = useState([]);
  const [savedRestaurantsDetails, setSavedRestaurantsDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchFinalized, setSearchFinalized] = useState(false);

  // Search-related state
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState("");

  // Function to add restaurant data to local storage
  function addRestaurantToLocalStorage(restaurantId, interactionType = "save") {
    let storedData = JSON.parse(localStorage.getItem("restaurantData")) || [];

    // Check if the restaurant is already stored
    const existingEntryIndex = storedData.findIndex(
      (item) => item.restaurantId === restaurantId
    );

    if (existingEntryIndex === -1) {
      // If not found, add new entry
      const newEntry = {
        restaurantId: restaurantId,
        mainCuisine: [], // Placeholder for future use
        interaction_type: interactionType, // Use the passed interaction type
      };
      storedData.push(newEntry);
    } else {
      // If found, update the interaction_type if needed
      storedData[existingEntryIndex].interaction_type = interactionType;
    }

    localStorage.setItem("restaurantData", JSON.stringify(storedData));
  }

  useEffect(() => {
    const fetchSavedRestaurants = async () => {
      if (!accessToken || !user || !location) return; // Wait until user, accessToken, and location are available

      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_PORT_URL}/api/savedrestaurants`,
          {
            lat: location.lat, // Pass lat from LocationContext
            lng: location.lng, // Pass lng from LocationContext
          },
          {
            withCredentials: true, // Ensure cookies are included
          }
        );

        // Log the full response object
        console.log("Full response from savedRestaurants API:", response);

        const savedRestaurants = response.data.result;

        // Log the savedRestaurants array in JSON format
        console.log("savedRestaurants array (JSON format):", JSON.stringify(savedRestaurants, null, 2));

        const restaurantIds = savedRestaurants.map(
          (restaurant) => restaurant.restaurant_id
        );

        // Log the extracted restaurant IDs
        console.log("Extracted restaurant IDs:", restaurantIds);

        setSavedRestaurantIds(restaurantIds);
        setSavedRestaurantsDetails(savedRestaurants); // Store full details
        setError(null); // Clear previous errors if any
      } catch (error) {
        console.error("Failed to fetch saved restaurants", error);
        setError("Failed to fetch saved restaurants");
      } finally {
        setLoading(false);
      }
    };

    if (accessToken && user && location) {
      fetchSavedRestaurants();
    }
  }, [accessToken, user, location]); // Trigger useEffect when accessToken, user, or location changes


  const saveRestaurant = async (restaurant) => {
    if (!user) return; // Wait until user is available

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/restaurants/save`,
        {
          restaurant_id: restaurant.restaurant_id,
        },
        {
          withCredentials: true, // Ensure cookies are included
        }
      );

      if (response.data.status === "Success") {
        setSavedRestaurantIds((prev) => [...prev, restaurant.restaurant_id]);
        setSavedRestaurantsDetails((prev) => [...prev, restaurant]); // Add to full details
        console.log("Saved restaurant successfully:", restaurant);
        setError(null); // Clear previous errors if any

        // Add the restaurant ID to local storage with "save" interaction type
        addRestaurantToLocalStorage(restaurant.restaurant_id, "save");
      }
    } catch (error) {
      console.error("Failed to save restaurant", error);
      setError("Failed to save restaurant");
    } finally {
      setLoading(false);
    }
  };

  const unsaveRestaurant = async (restaurantId) => {
    if (!user) return; // Ensure the user is authenticated before proceeding

    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/savedrestaurants/remove`,
        {
          withCredentials: true, // Ensure cookies are included
          data: {
            restaurant_id: restaurantId,
          },
        }
      );

      if (response.data.status === "Success") {
        setSavedRestaurantIds((prev) =>
          prev.filter((id) => id !== restaurantId)
        );
        setSavedRestaurantsDetails((prev) =>
          prev.filter((r) => r.restaurant_id !== restaurantId)
        ); // Remove from full details
        console.log("Removed restaurant from saved list:", restaurantId);
        setError(null); // Clear previous errors if any
      }
    } catch (error) {
      console.error("Failed to unsave restaurant", error);
      setError("Failed to unsave restaurant");
    } finally {
      setLoading(false);
    }
  };

  const clearSavedRestaurants = () => {
    setSavedRestaurantIds([]);
    setSavedRestaurantsDetails([]);
    console.log("Cleared all saved restaurants");
  };

  const fetchRecommendedRestaurants = async (searchTerm) => {
    setLoading(true);
    setSearchError("");

    try {
      const userInteractions =
        JSON.parse(localStorage.getItem("restaurantData")) || [];

      const payload = {
        lat: 43.856098,
        lng: -79.337021,
        userInteractions,
        searchquery: searchTerm,
        limit: 20,
        offset: 0,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/restaurantrecommendation`,
        payload,
        {
          withCredentials: true,
        }
      );

      console.log("API Response:", response.data); // Log the API response

      if (response.data && response.data.recommendations) {
        console.log(
          "Setting searchResults with:",
          response.data.recommendations
        ); // Log the data being set
        setSearchResults(response.data.recommendations);
        setSearchError("");
      } else {
        throw new Error(
          "Invalid response data format in fetchRecommendedRestaurants"
        );
      }
    } catch (error) {
      setSearchError("An error occurred while searching. Please try again.");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SavedRestaurantsContext.Provider
      value={{
        savedRestaurantIds,
        savedRestaurantsDetails,
        saveRestaurant,
        unsaveRestaurant,
        clearSavedRestaurants,
        loading,
        error,
      }}
    >
      <SearchContext.Provider
        value={{
          searchResults,
          searchError,
          fetchRecommendedRestaurants,
          setSearchResults, // Add this to the context
          setSearchError, // Add this to the context
          searchFinalized, // Add this to the context
          setSearchFinalized, // Add this to the context
        }}
      >
        {children}
      </SearchContext.Provider>
    </SavedRestaurantsContext.Provider>
  );
};
