import React, { useState } from 'react';
import App from './App'; // Import the main app component

const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const authStatus = localStorage.getItem('enteredTempPassword');
    return authStatus === 'true'; 
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_TEMP_PASSWORD) {
      localStorage.setItem('enteredTempPassword', 'true');
      setIsAuthenticated(true); 
    } else {
      setError('Incorrect password');
    }
  };

  if (isAuthenticated) {
    return <App />;
  }

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'flex-start', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#f5f5f5',
      textAlign: 'center' 
    }}>
      <h2 style={{ 
        color: '#333', 
        fontSize: '2rem', 
        marginBottom: '1.5rem',
        fontFamily: "'Poppins', sans-serif" 
      }}>Enter Password to Access Beta App</h2>
      <form 
        onSubmit={handleSubmit} 
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          style={{
            padding: '10px',
            fontSize: '1rem',
            marginBottom: '1rem',
            width: '100%',
            borderRadius: '20px',
            border: '1px solid #ccc',
            outline: 'none',
            boxSizing: 'border-box'
          }}
        />
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            fontSize: '1rem',
            backgroundColor: '#fb6f92',
            color: '#fff',
            border: 'none',
            borderRadius: '20px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease'
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#fb6f92')}
        >
          Submit
        </button>
      </form>
      {error && <p style={{ color: 'red', marginTop: '1rem' }}>{error}</p>}
    </div>
  );
};

export default PasswordPage;
