import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";
import debounce from "lodash.debounce";
import DOMPurify from "dompurify";
import RecentSearches from "./RecentSearches";
import SearchError from "./SearchError";
import SearchResults from "./SearchResults";
import { useSearch } from "../SavedRestaurantContext"; // Correctly import useSearch

const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [recentSearches, setRecentSearches] = useState(() => {
    const savedSearches = localStorage.getItem("recentSearches");
    return savedSearches ? JSON.parse(savedSearches) : [];
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [tempSearchResults, setTempSearchResults] = useState([]); // Temporary search results for the dropdown only
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  const { fetchRecommendedRestaurants, searchError, searchResults, setSearchError, setSearchResults, setSearchFinalized } = useSearch(); // Added setSearchError and setSearchResults

  const querySearch = async (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    if (!sanitizedValue.trim()) {
      setTempSearchResults([]); // Clear temporary results if input is empty
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_PORT_URL}/api/search`,
        {
          params: { query: sanitizedValue },
          withCredentials: true,
        }
      );

      if (response.data && response.data.restaurants) {
        const sanitizedResults = response.data.restaurants.map((result) => ({
          ...result,
          name: DOMPurify.sanitize(result.name),
          id: result.restaurant_id,
        }));
        setTempSearchResults(sanitizedResults); // Update temporary search results for dropdown
      } else {
        throw new Error("Invalid response data format in querySearch");
      }
    } catch (error) {
      setSearchError("An error occurred while searching. Please try again.");
      setTempSearchResults([]); // Clear temporary search results on error
    }
  };

  const debouncedSearch = useRef(debounce(querySearch, 500)).current;

  const updateRecentSearches = (value) => {
    setRecentSearches((prev) => {
      const updatedSearches = [
        value,
        ...prev.filter((search) => search !== value),
      ].slice(0, 3);
      localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
      return updatedSearches;
    });
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem("recentSearches");
  };

  const handleRecentSearchClick = (search) => {
    setSearchTerm(search);
    debouncedSearch(search);
    if (!isFullScreen) {
      setIsExpanded(false);
    }
  };

  const toggleSearchBar = () => {
    if (window.innerWidth <= 480) {
      setIsFullScreen(true);
      setTimeout(() => searchInputRef.current?.focus(), 0);
    } else {
      setIsExpanded((prev) => !prev);
      if (!isExpanded) {
        setTimeout(() => searchInputRef.current?.focus(), 0);
      } else {
        setTimeout(() => {
          searchInputRef.current?.blur(); // Explicitly blur the input
          document.activeElement?.blur(); // Remove focus from any element
        }, 100); // Small delay to ensure the input is blurred properly
      }
    }
  };

  const closeFullScreen = () => {
    const inputElement = searchInputRef.current;
    inputElement.classList.add("no-transition");
    setIsFullScreen(false);
    setTimeout(() => {
      inputElement.classList.remove("no-transition");
      inputElement.blur(); // Explicitly blur the input
      document.activeElement?.blur(); // Remove focus from any element
    }, 10);
  };

  const handleInputChange = (event) => {
    const nextValue = event.target.value;
    setSearchTerm(nextValue);
    debouncedSearch(nextValue);
    setShowRecentSearches(false);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchTerm) {
        console.log('Triggering fetchRecommendedRestaurants with searchTerm:', searchTerm);
        setSearchFinalized(true); 
        fetchRecommendedRestaurants(searchTerm);  
        hideDropdown();  
        navigate(`/results?query=${encodeURIComponent(searchTerm)}`);
        setTimeout(() => {
          searchInputRef.current?.blur(); // Explicitly blur the input after search
          document.activeElement?.blur(); // Remove focus from any element
        }, 100); // Small delay to ensure the input is blurred properly
      } else {
        setSearchError("Please enter a valid search term.");
      }
    }
  };

  const hideDropdown = () => {
    console.log('Hiding dropdown and resetting state');
    setSearchResults([]); // Clear the main search results
    setTempSearchResults([]); // Clear temporary search results
    setShowRecentSearches(false);
    setSearchTerm(""); // Clear the search term
    setIsExpanded(false); // Collapse the search input field
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
        setIsFullScreen(false);
        setShowRecentSearches(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div
      className={`search-bar ${isExpanded ? "expanded" : ""} ${
        isFullScreen ? "full-screen" : ""
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="text"
        className={`search-input ${isExpanded ? "expanded" : ""} ${
          searchError
            ? "with-error"
            : tempSearchResults.length > 0 || searchTerm
            ? "with-results"
            : "no-results"
        } ${
          showRecentSearches && recentSearches.length > 0
            ? "recent-searches-active"
            : ""
        } ${isFullScreen ? "full-screen-input" : ""}`}
        placeholder="Search..."
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyPress}
        ref={searchInputRef}
      />
      {!isExpanded && !isFullScreen && (
        <div className="search-icon" onClick={toggleSearchBar}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
      )}
      {isFullScreen && (
        <div className="close-icon" onClick={closeFullScreen}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      {searchError && <SearchError searchError={searchError} />}
      {showRecentSearches && recentSearches.length > 0 && (
        <RecentSearches
          recentSearches={recentSearches}
          handleRecentSearchClick={handleRecentSearchClick}
          clearRecentSearches={clearRecentSearches}
        />
      )}
      {(tempSearchResults.length > 0 || searchTerm) && (
        <SearchResults
          searchResults={tempSearchResults} // Use temporary search results for dropdown
          searchTerm={searchTerm}
          onResultClick={hideDropdown}
        />
      )}
    </div>
  );
};

export default SearchBar;
