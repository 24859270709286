import React from 'react';
import './Dropdown.css';

const SearchError = ({ searchError }) => (
  <div className="dropdown-content error">
    <div className="dropdown-item">{searchError}</div>
  </div>
);

export default SearchError;
