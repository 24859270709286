import { useState, useEffect, useCallback, useContext } from "react";
import { geocodeCoordinates } from "./LocationFetcher";
import { LocationContext } from "../../LocationContext";

const useLocation = (setAddress, setTempAddress, GOOGLE_API_KEY) => {
  const { setLocation } = useContext(LocationContext); // use context
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [latLng, setLatLng] = useState({ lat: null, lng: null });

  const fetchInitialLocation = useCallback(async () => {
    try {
      const storedAddress = localStorage.getItem("userAddress");
      const storedLat = localStorage.getItem("lat");
      const storedLng = localStorage.getItem("lng");

      if (storedAddress && storedLat && storedLng) {
        setAddress(storedAddress);
        setTempAddress(storedAddress);
        setLatLng({ lat: parseFloat(storedLat), lng: parseFloat(storedLng) });
        setLocation({ lat: parseFloat(storedLat), lng: parseFloat(storedLng) });
      } else {
        const dbAddress = await fetchAddressFromDatabase();
        if (dbAddress) {
          setAddress(dbAddress);
          setTempAddress(dbAddress);
          localStorage.setItem("userAddress", dbAddress);
        } else {
          await fetchGeolocation();
        }
      }
    } catch (error) {
      console.error("Error fetching initial location:", error);
    }
  }, [setAddress, setTempAddress, setLocation]);

  useEffect(() => {
    fetchInitialLocation();
  }, [fetchInitialLocation]);

  const fetchAddressFromDatabase = async () => {
    try {
      // Simulate fetching address from a database
      return null;
    } catch (error) {
      console.error("Error fetching address from database:", error);
      return null;
    }
  };

  const formatAddress = (address) => {
    const addressComponents = address.split(",");
    const streetPart = addressComponents[0].trim();
    const secondPart = addressComponents[1] && addressComponents[1].trim();
    const hasStreetSuffix =
      secondPart &&
      (secondPart.match(
        /\b(st|street|ave|avenue|blvd|boulevard|rd|road|dr|drive|ln|lane|pl|place|court|ct|way|terr|terrace|cres|crescent|parkway|pkwy|circle|cir)\b/i
      ) ||
        /\d/.test(secondPart));

    return hasStreetSuffix ? `${streetPart}, ${secondPart}` : streetPart;
  };

  const fetchGeolocation = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const initialAddress = await geocodeCoordinates(
              latitude,
              longitude,
              GOOGLE_API_KEY
            );
            const formattedAddress = formatAddress(initialAddress);
            setAddress(formattedAddress);
            setTempAddress(formattedAddress);
            setLatLng({ lat: latitude, lng: longitude });
            setLocation({ lat: latitude, lng: longitude });
            localStorage.setItem("userAddress", formattedAddress);
            localStorage.setItem("lat", latitude);
            localStorage.setItem("lng", longitude);
            resolve();
          } catch (error) {
            console.error("Error fetching geolocation:", error);
            const defaultAddress = "101 Town Center Blvd";
            const formattedDefaultAddress = formatAddress(defaultAddress);
            setAddress(formattedDefaultAddress);
            setTempAddress(formattedDefaultAddress);
            setLatLng({ lat: 43.856098, lng: -79.337021 });
            setLocation({ lat: 43.856098, lng: -79.337021 });
            localStorage.setItem("userAddress", formattedDefaultAddress);
            localStorage.setItem("lat", 43.856098);
            localStorage.setItem("lng", -79.337021);
            resolve();
          }
        },
        (error) => {
          console.error("Error using geolocation:", error);
          const defaultAddress = "101 Town Center Blvd";
          const formattedDefaultAddress = formatAddress(defaultAddress);
          setAddress(formattedDefaultAddress);
          setTempAddress(formattedDefaultAddress);
          setLatLng({ lat: 43.856098, lng: -79.337021 });
          setLocation({ lat: 43.856098, lng: -79.337021 });
          localStorage.setItem("userAddress", formattedDefaultAddress);
          localStorage.setItem("lat", 43.856098);
          localStorage.setItem("lng", -79.337021);
          resolve();
        }
      );
    });
  };

  const fetchCurrentLocation = async (closeFullScreen) => {
    setLoadingLocation(true);
    try {
      await fetchGeolocation();
    } catch (error) {
      console.error("Error fetching current location:", error);
    }
    setLoadingLocation(false);
    if (closeFullScreen) closeFullScreen();
  };

  return { loadingLocation, fetchCurrentLocation, latLng };
};

export default useLocation;
